<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div class="page-header">
      Kollektivverträge
    </div>

    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
      Die Kollektivverträge werden geladen<br />
      <div style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"></div>
    </div>
    <div v-if="!loading" class ="tablecontainer scrollbar-container">
      <!-- LIST OF COLLECTIVE AGREEMENTS -->
      <div class="collective-agreement-list">
        <div class="collective-agreement list-header">
          Kollektivverträge
        </div>
        <div class="collective-agreement-list-overflow" style="overflow: auto">
          <div v-for="collectiveAgreementCountry,index in Object.entries(contractCollectiveAgreements).filter(c => Object.values(storeCountries).includes(c[0])).sort((a,b)=>Object.values(a)[0].countryId - Object.values(b)[0].countryId)" v-bind:key="'countryIndex_' + index" class="collective-agreement-country-container">
              <div class="collective-agreement-country-title" >
                {{ countryEnumMap[Object.values(collectiveAgreementCountry[1])[0].countryId].valueGER }}
              </div>
              <div v-for="collectiveAgreement in Object.values(collectiveAgreementCountry[1])" class="collective-agreement" v-on:click="selectedCollectiveAgreement=collectiveAgreement; setCurrentValidFromDate(); nameFilter=''; updateFilter();"
                  v-bind:class="{'selected': selectedCollectiveAgreement.id===collectiveAgreement.id}" v-bind:key="collectiveAgreement.id">
                  {{ collectiveAgreement.name }}
              </div>
          </div>
        </div>
      </div>
      <!-- ACTUAL SETTINGS -->
      <div class="collective-agreement-wagetable-date-list">
        <div class="collective-agreement-wagetable-date list-header">
          Gültig ab
        </div>
        <div v-for="validFromDate in getValidFromDates()" class="collective-agreement-wagetable-date" v-on:click="selectedValidFromDate=validFromDate"
              v-bind:class="{'selected': selectedValidFromDate===validFromDate}" v-bind:key="validFromDate">
          {{ printValidFromDate(validFromDate) }}
        </div>
      </div>
      <div class="collective-agreement-wagetable-container">
        <div class="collective-agreement-wagetable-content">
          <div style="display:flex; justify-content:space-between; align-items: center; padding: 0px 30px;">
              <h3>Lohntabelle</h3>
              <div>
                <input class="searchInput" v-model="nameFilter" placeholder="Tarifgruppe" @keyup.enter="updateFilter()"/>
                <div class="searchArea" @click="updateFilter()">
                  <div class="searchButton" ><i class="far fa-magnifying-glass" style="cursor: pointer;"></i> Filter anwenden</div>
                </div>
              </div>
            </div>
          <table v-show="selectedValidFromDate != null" style="width: 100%">
            <tr>
              <th>Tarifgruppe</th>
              <th>Betrag</th>
              <th>Zahlungstyp</th>
              <th>Lohntyp</th>
              <th>Bundesländer <i class="fas fa-eye" v-on:click="openFilterStatesPopup($event)"/></th>
            </tr>
            <tr v-for="wg in getNormalizedWageGroupsByValidFromDate(selectedValidFromDate)" v-bind:key="wg.id + '_' + wg.amountId + '_' + wg.header + '_' + wg.empty">
              <td v-if="wg.header && wg.empty" colspan="5">!! Noch kein Lohn für {{ wg.name }} ({{ wg.stateIds.map(stateId => countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].find(e => e.stateId == stateId).valueGER).join(', ') }}) hinterlegt !!</td>
              <td v-if="wg.header && !wg.empty" colspan="5">
                <div style="height: 20px"></div>
                <!-- <b-button
                    style="width: calc(100% - 10px); margin: 0px 5px 20px 5px;"
                    @click="openCreateNewContractWageAmountPopup(wg.id, selectedValidFromDate)"
                    v-tooltip="'anlegen'"
                    squared
                    variant="outline-success"
                >Neuen {{ wg.name }} Lohn anlegen</b-button> -->
              </td>
              <td v-if="!wg.header">{{ wg.name }}</td>
              <td v-if="!wg.header">{{ wg.amount ? (wg.amount / 100.0).toFixed(2) : "-" }}</td>
              <td v-if="!wg.header">{{ wg.paymentType ? paymentTypeArray[wg.paymentType].label : "-" }}</td>
              <td v-if="!wg.header">{{ wg.wageType ? wageTypeArray[wg.wageType].label : "-" }}</td>
              <td v-if="!wg.header">{{ wg.stateIds ? wg.stateIds.length == countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].length ? "Alle Bundesländer" : wg.stateIds.map(stateId => countryStateEnumMapByCountry[selectedCollectiveAgreement.countryId].find(e => e.stateId == stateId).valueGER).join(', ') : "-" }}</td>
            </tr>
          </table>
          <div v-show="selectedValidFromDate == null">
            <div style="width: 100%; text-align: center; color: #222222">
              Für diesen Kollektivvertrag existieren noch keine Tarifgruppen.
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'collectiveAgreements',
  data () {
    return {
      loading: true,
      selectedCollectiveAgreement: null,
      selectedValidFromDate: null,

      contractCollectiveAgreements: {},
      contractWageGroups: {},
      countryStateEnumMapByCountry: {},
      storeCountries: {},

      wageTypeArray: [],
      paymentTypeArray: [],

      stateFilter: [],
      nameFilter: '',
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'uiDataMap', 'countryEnumMap', 'countryStateEnumMap', 'storeMap']),
    dateDisplay: function() {
      return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
    },
    headerButtons() {
        let view = this;
        let state = {
          buttons: [
            {
              type: "block",
              icon: "fa-refresh",
              click: () => {
                this.refresh();
              },
              show: true,
              disabled: false,
              tooltip: "Daten neu laden",
              title: "Daten neu laden"
            },
          ]
        };
        return state;
    },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
    selectedCollectiveAgreement: function (newVal, oldVal) {
      // console.log("selectedCollectiveAgreement", newVal, oldVal);
    }
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
      let view = this;
      view.loading = true;
      view.stateFilter = [];
      view.nameFilter = '';

      view.setupUIDataMaps();

      let promises = [];
      promises.push(view.getContractCollectiveAgreements());
      promises.push(view.$helpers.GetStores());
      // promises.push(view.getContractWageGroups()); // this is not necessary anymore -> wage groups are loaded with collective agreements

      Promise.all(promises)
      .then((responses) => {
        view.countryStateEnumMapByCountry = {};
        Object.values(view.countryStateEnumMap).forEach(c => {
          if (!view.countryStateEnumMapByCountry.hasOwnProperty(c.countryId)) {
            view.countryStateEnumMapByCountry[c.countryId] = [];
          }
          view.countryStateEnumMapByCountry[c.countryId].push(c);
        });
        // console.log("countryStateEnumMapByCountry", view.countryStateEnumMapByCountry);

        view.storeCountries = {};
        Object.values(view.storeMap).forEach(s => {
          if (!view.storeCountries.hasOwnProperty(s.country)) {
            view.storeCountries[s.country] = ""+s.country;
          }
        });

        view.selectedCollectiveAgreement = Object.values(Object.entries(view.contractCollectiveAgreements).filter(c => Object.values(view.storeCountries).includes(c[0])).sort((a,b)=>Object.values(a)[0].countryId - Object.values(b)[0].countryId)[0][1])[0];

        view.setCurrentValidFromDate();

        view.loading = false;
      });
    },

    // helpers
    setupUIDataMaps() {
      this.uiDataMap.wageTypes.forEach(item => {
        item.label = item.label_de;
        item.value = item.id;
        this.wageTypeArray[item.id] = item;
      });
      this.uiDataMap.paymentTypes.forEach(item => {
        item.label = item.label_de;
        item.value = item.id;
        this.paymentTypeArray[item.id] = item;
      });
    },
    printValidFromDate(validFrom) {
      let date = new Date(validFrom);
      let year = new Intl.DateTimeFormat('de', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('de', { month: '2-digit' }).format(date);
      let day = new Intl.DateTimeFormat('de', { day: '2-digit' }).format(date);
      return `${day}.${month}.${year}`;
    },
    getNormalizedWageGroupsByValidFromDate(validFrom) {
      let view = this;
      let normalizedWageGroups = [];

      if (view.contractWageGroups[view.selectedCollectiveAgreement.id] == undefined) {
        return normalizedWageGroups;
      }

      let countryStateFilter = view.stateFilter.map(e => Object.values(view.countryStateEnumMap).find(c => c.id == e).stateId);

      view.contractWageGroups[view.selectedCollectiveAgreement.id]
        .sort((a,b) => a.stateIds[0] - b.stateIds[0])
        .filter(wg => wg.show && (view.stateFilter.length == 0 || wg.stateIds.some(e => !countryStateFilter.includes(e))))
        .forEach(wg => {
        let currentWageGroup = [];
        wg.wage_amounts.filter(wa => wa.validFrom === validFrom).forEach(wa => {
          let wageGroup = {};
          wageGroup.name = wg.name;
          wageGroup.id = wg.id;
          wageGroup.paymentType = wa.paymentType;
          wageGroup.wageType = wa.wageType;
          wageGroup.amount = wa.amount;
          wageGroup.amountId = wa.id;
          wageGroup.stateIds = wg.stateIds;
          currentWageGroup.push(wageGroup);
        });
        if (currentWageGroup.length == 0)
          currentWageGroup.push({name: wg.name, id: wg.id, stateIds: wg.stateIds, header: true, empty: true});
        currentWageGroup.push({name: wg.name, id: wg.id, header: true, empty: false});
        normalizedWageGroups = normalizedWageGroups.concat(currentWageGroup);
      });
      return normalizedWageGroups;
    },
    getValidFromDates() {
      let view = this;
      let validFromDates = [];

      // console.log(view.selectedCollectiveAgreement);

      if (view.contractWageGroups[view.selectedCollectiveAgreement.id] == undefined) {
        return validFromDates;
      }

      // get all from dates
      view.contractWageGroups[view.selectedCollectiveAgreement.id].forEach(wg => {
        wg.wage_amounts.forEach(wa => {
          if (!(validFromDates.includes(wa.validFrom))) {
            validFromDates.push(wa.validFrom.toString());
          }
        });
      });
      validFromDates = validFromDates.sort((a,b) => {
        return new Date(b) - new Date(a);
      });
      return validFromDates;
    },
    setCurrentValidFromDate() {
      let view = this;
      let validFromDates = view.getValidFromDates();
      if (validFromDates.length > 0) {
        view.selectedValidFromDate = validFromDates[0];
      } else {
        view.selectedValidFromDate = null;
      }
    },
    openFilterStatesPopup($event) {
      let view = this;
      var target = $event.currentTarget;
      console.log(view.stateFilter);

      let stateFilterLocal = view.countryStateEnumMapByCountry[view.selectedCollectiveAgreement.countryId].map(e => {
        return {
          key: e.id, // use id instead of stateId to avoid confusion with different countries
          label: e.valueGER,
          toggled: !view.stateFilter.includes(e.id),
        };
      });
      // console.log(stateFilterLocal);

      this.$helpers.OpenPopup({
        elRelative: target,
        type: "list",
        componentState: {
          entries: stateFilterLocal,
        },
        remainAfterSelect: true,
        pointerDirection: 0,
        iconsLeft: true,
        selectCallback: function ($event, item, dropdown) {
          item.toggled = !item.toggled;
          if (view.stateFilter.includes(item.key)) {
            view.stateFilter = view.stateFilter.filter(e => e !== item.key);
          } else {
            view.stateFilter.push(item.key);
          }
        },
      });
    },
    updateFilter(){
      this.contractWageGroups[this.selectedCollectiveAgreement.id].forEach(wg => {
        if(this.nameFilter == ''){
          wg.show = true;
        }else{
          let filterParts = this.nameFilter.split(' ');
          let wgNameParts = wg.name.split(' ');
          let match = true;
          filterParts.forEach(fp => {
            if(!wgNameParts.some(wgnp => wgnp.toLowerCase().includes(fp.toLowerCase()))){
              match = false;
            }
          });
          if (match) {
            wg.show = true;
          }else{
            wg.show = false;
          }
        }
      });
    },


    // collective agreements
    getContractCollectiveAgreements() {
      let view = this;

      let url = '/api/sec/contract/collectiveAgreement';
      const params = {
        companyId: this.$store.state.companyId,
      };

      return this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params
      }).then((response) => {
        view.contractCollectiveAgreements = {};
        view.contractWageGroups = {};
        response.data.forEach(r => {
          if (!view.contractCollectiveAgreements.hasOwnProperty(r.countryId)) {
            view.contractCollectiveAgreements[r.countryId] = {};
          }
          view.contractCollectiveAgreements[r.countryId][r.id] = r;
          r.wage_groups.forEach(wg => {
            wg.show = true;
          });
          view.contractWageGroups[r.id] = r.wage_groups;
        });
      }).catch((error) => {
        console.log(error);
        view.$helpers.error("Fehler beim Laden der Kollektivverträge", "Die Kollektivverträge konnten nicht geladen werden. Bitte versuchen Sie es erneut.");
      });
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-view-padding {
  padding-left: 0px;
}

.page-header {
  font-size: 32px;
  text-align: left;
  color: #e51550;
  border-bottom:1px solid #ccc;
  font-weight:400;
  padding: 5px 5px 5px 20px;
}

.has-header > .scrollbar-container{
  position: initial;
  height: calc(100% - var(--header-height) - 20px) !important;
  top: var(--header-height);
  overflow-x: hidden;
}

.tablecontainer {
  display: flex;
}

.list-header {
  font-weight:400;
  padding: 5px 5px 5px 20px;
  border-bottom: thick solid var(--bg);
}

.collective-agreement-list {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-list > .collective-agreement,
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement{
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-list > .collective-agreement:not(.list-header):hover,
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement:not(.list-header):hover,
.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-list > .collective-agreement:nth-child(odd),
.collective-agreement-list > .collective-agreement-list-overflow > .collective-agreement-country-container > .collective-agreement:nth-child(odd){
  background: var(--contrast-1);
}
.collective-agreement-list-overflow {
  overflow: auto;
  height: calc(100% - 40px);
}
.collective-agreement-country-title {
  font-weight: bold;
  padding-left: 10px;
  /* padding-top: 20px; */
  /* background: var(--contrast-1); */
}
.collective-agreement-settings {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-settings > .collective-agreement-setting {
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-settings > .collective-agreement-setting:not(.list-header):hover,.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-settings > .collective-agreement-setting:nth-child(even){
  background: var(--contrast-1);
}

.collective-agreement-wagetable-container {
  width: calc(100% - 500px);
  height: 100%;
  display: flex;
}
.collective-agreement-wagetable-content {
  width: 100%;
  overflow: auto;
}
.collective-agreement-wagetable-date-list {
  height: 100%;
  width: 250px;
  text-align: left;
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date {
  min-height: 40px;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: var(--contrast-2);
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date:not(.list-header):hover,.selected {
  color: var(--bg);
  background: var(--ml) !important;
}
.collective-agreement-wagetable-date-list > .collective-agreement-wagetable-date:nth-child(odd){
  background: var(--contrast-1);
}

.collective-agreement-content-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.collective-agreement-content {
  height: 100%;
}


.btn-outline-success {
  color: var(--ml);
  border-color: var(--ml);
  margin-bottom: 2px;
  margin-right: 2px;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

.searchInput{
    height: 23px;
    font-size: 12px;
    /* padding: 0px; */
    padding-left: 10px;
    width: 170px;
    background: white;
    box-shadow: inset 0 0 0 1px #cccccc;
}
.searchArea{
    border-radius: 30px;
    margin-left: 5px;
    /* margin-right: 10px; */
    width: 110px;
    text-align: center;
    height: 23px;
    /* margin-bottom: 15px; */
    line-height: 23px;
    /* margin-top: 8px; */
    display: inline-block;
    cursor: pointer;
}
.searchArea > .searchButton{
    border-radius: 30px;
    height: 23px;
    /* margin-right: 15px; */
    /* line-height: 15px; */
    font-size: 11px;
    font-weight: bold;
    font-family: DistrictProBook;
    cursor: pointer;
    color: #888;
    background-color: #e5e5e5;
    box-shadow: rgb(128 128 128) 0px 0px 0px 1px inset;
    cursor: pointer;
    text-shadow: #ffffff 1px 0 10px;
}
.searchArea > .searchButton:hover{
    background-color:#404040;
    font-weight:normal;
    color:#fff;
    cursor: pointer;
    box-shadow: rgb(200 200 200) 0px 0px 0px 1px inset;
}

</style>
